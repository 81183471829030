import 'regenerator-runtime/runtime';

// DOM Elements
const connectButton = document.getElementById('connect');
const connectionIndicator = document.getElementById('connectionIndicator');
const bluetoothStatus = document.getElementById('bluetoothStatus');
const canvas = document.getElementById('graph');
const ctx = canvas.getContext('2d');
let graphData = [];

// Define BLE Device Specs
const SERVICE_UUID = "77e975c1-8b3a-4c29-976b-44dce8a27a34";
const CHARACTERISTIC_UUID = "c0fe2ba6-320f-4bc1-9112-847df1bf658e";

// BLE Object
const ble = {
    device: null,
    server: null,
    service: null,
    characteristic: null,
    isConnected: false,
    pollingInterval: null,

    toggleConnection: async function() {
        if (this.isConnected) {
            this.disconnect();
        } else {
            await this.connect();
        }
    },

    connect: async function() {
        if (!this.isWebBluetoothEnabled()) {
            bluetoothStatus.innerText = "Web Bluetooth API is not available in this browser/device!";
            return;
        }
        
        try {
            bluetoothStatus.innerText = 'Connecting...';
            this.device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true,
                optionalServices: [SERVICE_UUID]
            });

            this.device.addEventListener('gattserverdisconnected', this.onDisconnected);
            this.server = await this.device.gatt.connect();
            this.service = await this.server.getPrimaryService(SERVICE_UUID);
            const characteristics = await this.service.getCharacteristics();

            const characteristic = characteristics.find(char => char.uuid === CHARACTERISTIC_UUID);
            if (!characteristic) {
                throw new Error('Characteristic UUID not found');
            }

            this.characteristic = characteristic;
            console.log(`Found desired characteristic ${CHARACTERISTIC_UUID}`);
            console.log(characteristic)

            this.isConnected = true;
            connectionIndicator.classList.remove('status-disconnected');
            connectionIndicator.classList.add('status-connected');

            try {
                console.log('Attempting to start notifications');
                await this.characteristic.startNotifications();
                console.log('Notifications started');
                this.characteristic.addEventListener('characteristicvaluechanged', this.handleCharacteristicValueChanged);
            } catch (error) {
                console.error('Error starting notifications, switching to polling:', error);
                this.startPollingCharacteristic();
            }
        } catch (error) {
            console.error('Error: ', error);
            bluetoothStatus.innerText = 'Connection failed';
        }
    },

    disconnect: function() {
        if (this.device && this.device.gatt.connected) {
            this.device.gatt.disconnect();
            console.log('Disconnecting...');
        }
    },

    onDisconnected: function() {
        console.log('Device disconnected');
        bluetoothStatus.innerText = 'Device disconnected';
        ble.isConnected = false;
        clearInterval(ble.pollingInterval);
        connectionIndicator.classList.add('status-disconnected');
        connectionIndicator.classList.remove('status-connected');
    },

    handleCharacteristicValueChanged: (event) => {
        console.log('Characteristic value changed');
        console.log(event);
    
        let valueBuffer = event.target.value;
        if (valueBuffer.byteLength >= 5) { // Check if the buffer has at least 5 bytes (1 byte for identifier + 4 bytes for data)
            let identifier = valueBuffer.getUint8(0); // First byte is the identifier
            let value = valueBuffer.getFloat32(1, true); // Read the next 4 bytes as float
            
            if (identifier === 0x01) { // If identifier is for sensor reading
                const sensorReading = valueBuffer.getInt32(1, true);
                console.log(`Sensor Reading 1: ${sensorReading}`);
                if (graphData.length > canvas.width) {
                    graphData.shift();
                }
                const scaledSensorReading = (sensorReading / 4096) * canvas.height;
                graphData.push(scaledSensorReading);
                ble.drawGraph(); // Update graph with new sensor reading
    
            } else if (identifier === 0x02) { // If identifier is for jump height
                console.log('Jump Height:', value);
                ble.updateJumpHeight(value); // Update jump height display
            }
        } else {
            console.log('No or insufficient data available in the characteristic');
        }
    },
    
    startPollingCharacteristic: function() {
        console.log('Starting to poll characteristic');
        const POLL_INTERVAL_MS = 1000;
        this.pollingInterval = setInterval(async () => {
            if (this.characteristic && !this.isDisconnected) {
                try {
                    console.log('Reading characteristic value...');
                    const valueBuffer = await this.characteristic.readValue();
                    console.log('Characteristic value:', valueBuffer);
                    if (valueBuffer.byteLength > 0) {
                        let value = valueBuffer.getFloat32(0, true);
                        graphData.push(value);
                    }
                } catch (error) {
                    console.error('Error reading value:', error);
                }
            }
        }, POLL_INTERVAL_MS);
    },

    isWebBluetoothEnabled: function() {
        if (!navigator.bluetooth) {
            console.log("Web Bluetooth API is not available in this browser!");
            return false;
        }
        console.log('Web Bluetooth API supported in this browser.');
        return true;
    },

    drawGraph: function() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.moveTo(0, canvas.height - graphData[0]);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'orange';
        for (let i = 1; i < graphData.length; i++) {
            ctx.lineTo(i, canvas.height - graphData[i]);
        }

        ctx.stroke();
    },

    updateJumpHeight: function(value) {
        const tableBody = document.querySelector("#jumpTable tbody");
        const newRow = tableBody.insertRow();
        const timeCell = newRow.insertCell(0);
        const jumpHeightCell = newRow.insertCell(1);
        const hangTimeCell = newRow.insertCell(2);

        timeCell.textContent = new Date().toLocaleTimeString();
        jumpHeightCell.textContent = value.toFixed(2);

        hangTimeCell.textContent = Math.sqrt((2 * value * 0.0254 ) / 9.81).toFixed(2);
    },
};

// Connect Button Event Listener
connectButton.addEventListener('click', () => ble.toggleConnection());


// //Firmware:
// #include <BLEDevice.h>
// #include <BLEUtils.h>
// #include <BLEServer.h>
// #include <BLE2902.h>

// #define SERVICE_UUID        "77e975c1-8b3a-4c29-976b-44dce8a27a34" // Replace with your unique UUID
// #define CHARACTERISTIC_UUID "c0fe2ba6-320f-4bc1-9112-847df1bf658e" // Replace with your unique UUID

// BLEServer *pServer = NULL;
// BLECharacteristic *pCharacteristic = NULL;

// const int sensorPin = 25;
// const int debounceThreshold = 120;
// const int minHeight = 3;
// const int maxHeight = 200;

// unsigned long jumpStartTime = 0;
// unsigned long jumpEndTime = 0;
// unsigned long jumpDuration = 0;
// unsigned long standingStartTime = 0;

// class MyServerCallbacks: public BLEServerCallbacks {
//     void onConnect(BLEServer* pServer) {
//         Serial.println("Client Connected");
//     };

//     void onDisconnect(BLEServer* pServer) {
//         Serial.println("Client Disconnected");
//         BLEAdvertising *pAdvertising = BLEDevice::getAdvertising();
//         pAdvertising->start();
//     }
// };

// float calculateJumpHeightInches(float airTimeInSeconds) {
//     return 0.125 * 9.81 * airTimeInSeconds * airTimeInSeconds * 39.3701;
// }

// enum State {
//   WAITING, READY_TO_JUMP, JUMPING, POST_JUMP
// };

// State currentState = WAITING;

// void setup() {
//   Serial.begin(9600);
//   pinMode(sensorPin, INPUT);

//   BLEDevice::init("Vertical Jump Tester");
//   pServer = BLEDevice::createServer();  
//   pServer->setCallbacks(new MyServerCallbacks());
//   BLEService *pService = pServer->createService(SERVICE_UUID);
//   pCharacteristic = pService->createCharacteristic(
//                       CHARACTERISTIC_UUID,
//                       BLECharacteristic::PROPERTY_READ |
//                       BLECharacteristic::PROPERTY_WRITE |
//                       BLECharacteristic::PROPERTY_NOTIFY |
//                       BLECharacteristic::PROPERTY_INDICATE
//                     );
//   pCharacteristic->addDescriptor(new BLE2902());

//   pService->start();
//   BLEAdvertising *pAdvertising = pServer->getAdvertising();
//   pAdvertising->start();
//   float initVal = 0.69;
//   pCharacteristic->setValue(reinterpret_cast<uint8_t*>(&initVal), sizeof(initVal));
// }

// void loop() {
//   int sensorValue = analogRead(sensorPin);
//   unsigned long currentTime = millis();
//   static unsigned long lastSensorNotificationTime = 0;
//   const unsigned long sensorNotificationInterval = 100; // Notify every 100 ms

//   // Notify sensor reading periodically
//   if (currentTime - lastSensorNotificationTime > sensorNotificationInterval) {
//       uint8_t sensorData[5]; // 1 byte for identifier + 4 bytes for sensor value
//       sensorData[0] = 0x01; // Identifier for sensor reading
//       int32_t sensorValueInt32 = static_cast<int32_t>(sensorValue); // Convert to int32_t
//       memcpy(&sensorData[1], &sensorValueInt32, sizeof(sensorValueInt32)); // Copy sensor value
//       pCharacteristic->setValue(sensorData, sizeof(sensorData));
//       pCharacteristic->notify();
//       lastSensorNotificationTime = currentTime;
//   }

//   switch (currentState) {
//     case WAITING:
//       // Serial.println(sensorValue);
//       if (sensorValue > debounceThreshold) {
//         Serial.println("READY TO JUMP");
//         currentState = READY_TO_JUMP;
//       }
//       break;

//     case READY_TO_JUMP:
//       // Waiting for the jump to start
//       if (sensorValue <= debounceThreshold) {
//         Serial.println("STARTING JUMP");
//         jumpStartTime = currentTime;
//         currentState = JUMPING;
//       }
//       break;

//     case JUMPING:
//       // Waiting for the jump to end
//       if (sensorValue > debounceThreshold) {
//         jumpEndTime = currentTime;
//         jumpDuration = jumpEndTime - jumpStartTime;

//         float airTimeInSeconds = static_cast<float>(jumpDuration) / 1000.0;
//         float jumpHeight = calculateJumpHeightInches(airTimeInSeconds); 

//         // float jumpHeight = static_cast<float>(jumpDuration) / 1000.0 * 9.8 * 0.5 * 0.0254 * 12.0;

//         if (jumpHeight >= minHeight && jumpHeight <= maxHeight) {
//           Serial.print("Jump Height: ");
//           Serial.print(jumpHeight);
//           Serial.println(" inches");
//           Serial.print("Air Time: ");
//           Serial.print(static_cast<float>(jumpDuration) / 1000.0);
//           Serial.println(" seconds");
//           Serial.println("Moving to POST JUMP STATE");
//           currentState = POST_JUMP;
//           standingStartTime = currentTime; // Start timing standing duration

//           // pCharacteristic->setValue(reinterpret_cast<uint8_t*>(&jumpHeight), sizeof(jumpHeight));
//           // Notify for jump event
//           uint8_t jumpData[5]; // 1 byte for identifier + 4 bytes for jump height
//           jumpData[0] = 0x02; // Identifier for jump height
//           memcpy(&jumpData[1], &jumpHeight, sizeof(jumpHeight));
//           pCharacteristic->setValue(jumpData, sizeof(jumpData));
//           pCharacteristic->notify();
//           delay(3);
//         } else{
//           Serial.println("Moving to WAITING STATE");
//           currentState = WAITING;
//         }
//       } else if (currentTime - jumpStartTime > 3000){
//         Serial.println("Moving to WAITING STATE");
//         currentState = WAITING;
//       }
//       break;

//     case POST_JUMP:
//       // Wait for user to get ready for another jump or step off
//       if (sensorValue <= debounceThreshold) {
//         if (currentTime - jumpEndTime > (maxHeight * 1000.0 / 9.8 / 2)) {
//           Serial.println("Moving to WAITING STATE");
//           currentState = WAITING;
//         }
//       } else {
//         // Check if the user has been standing for more than 3 seconds
//         if (currentTime - standingStartTime > 3000) {
//           Serial.println("READY TO JUMP AGAIN");
//           currentState = READY_TO_JUMP;
//         }
//       }
//       break;
//   }
//   delay(10);
// }